import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"unichrome"} />
		<Helmet>
			<title>
				Unichrome: A 1-Bit Unicorn Adventure
			</title>
			<meta name={"description"} content={"Videogame Unichrome: A 1-Bit Unicorn Adventure on Steam, Playstation, Xbox and Nintendo Switch."} />
			<meta property={"og:title"} content={"Unichrome: A 1-Bit Unicorn Adventure"} />
			<meta property={"og:description"} content={"Run-and-gun platforming single-player arcade action mixed with off-kilter comedy. Horn-dash, Triple-Barrel-Shotgun-Blast, Molotov-Cocktail, and Rainbow-Bazooka-Blast your enemies to their death. Homing bullets, automated turrets, and ice-beams spice up the rainbow-colored carnage.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/New-Art-16x9-4k-cover.png?v=2022-09-09T00:10:26.928Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ic.png?v=2022-09-08T22:36:53.980Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Menuu />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/New-Art-16x9-4k-cover.png?v=2022-09-09T00:10:26.928Z) center/cover" md-background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/New-Art-16x9-4k-cover.png?v=2022-09-09T00:10:26.928Z) center/cover no-repeat scroll">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="460px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					margin="0"
					font="--base"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					BY{" "}
					<Link href="https://twitter.com/Super8BitRafa" target="_blank" color="#000000">
						Super8bitRafa
					</Link>
				</Text>
				<Text as="h1" margin="0 0 16px 0" font="--headline2" lg-font="--headline2">
					Unichrome: A 1-Bit Unicorn Adventure
				</Text>
				<Text as="p" margin="16px 0" font="--base" max-width="400px">
					Run-and-gun platforming single-player arcade action mixed with off-kilter comedy. Horn-dash, Triple-Barrel-Shotgun-Blast, Molotov-Cocktail, and Rainbow-Bazooka-Blast your enemies to their death. Homing bullets, automated turrets, and ice-beams spice up the rainbow-colored carnage.
					<br />
				</Text>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://store.steampowered.com/app/2026340/Unichrome_A_1Bit_Unicorn_Adventure/"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Steam (PC)
				</Link>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://store.playstation.com/en-us/concept/10005461"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Playstation
				</Link>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://www.xbox.com/games/store/unichrome-a-1-bit-unicorn-adventure/9NLW71S732ST"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Xbox One
				</Link>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://www.nintendo.com/store/products/unichrome-a-1-bit-unicorn-adventure-switch/"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Nintendo Switch (America)
				</Link>
				<Link
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					target="_blank"
					border-radius="2px"
					text-align="center"
					href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Unichrome-A-1-Bit-Unicorn-Adventure-2261887.html"
					width="100%"
				>
					Nintendo Switch (Europe)
				</Link>
				<Link
					text-decoration-line="initial"
					text-align="center"
					href="https://store-jp.nintendo.com/list/software/70010000055618.html"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					color="#F7FBFF"
					display="inline-block"
					hover-background="--color-darkL1"
					target="_blank"
					margin="3px 3px 3px 3px"
					border-radius="2px"
					width="100%"
				>
					Nintendo Switch (Japan)
				</Link>
			</Box>
		</Section>
		<Section text-align="center" padding="10px 0 10px 0" sm-padding="40px 0">
			<Box display="flex" margin="10px 0 10px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px" width="100%">
					<Image width="100%" max-width="100%" src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ezgif-2-c40405a6c7.gif?v=2022-09-08T22:48:15.074Z" />
				</Box>
				<Box width="100%" padding="10px">
					<Image src="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/ezgif-2-af2c5fc99b.gif?v=2022-09-08T22:48:15.065Z" width="100%" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section text-align="center" padding="10px 0 10px 0" sm-padding="40px 0">
			<Box display="flex" margin="10px 0 10px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px" width="100%">
					<Image
						width="100%"
						max-width="100%"
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22:34:51.491Z"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22%3A34%3A51.491Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22%3A34%3A51.491Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22%3A34%3A51.491Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22%3A34%3A51.491Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22%3A34%3A51.491Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22%3A34%3A51.491Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_63.jpg?v=2022-09-08T22%3A34%3A51.491Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box width="100%" padding="10px">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22:35:06.540Z"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22%3A35%3A06.540Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22%3A35%3A06.540Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22%3A35%3A06.540Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22%3A35%3A06.540Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22%3A35%3A06.540Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22%3A35%3A06.540Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/DEVXBOX_Image_46.jpg?v=2022-09-08T22%3A35%3A06.540Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/VvJmGf.png?v=2022-09-27T14:36:13.884Z) 50% 15%/cover"
			color="--light"
			font="--base"
			sm-background="--color-dark url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/VvJmGf.png?v=2022-09-27T14:36:13.884Z) 50% 15%/contain repeat-x scroll"
			lg-background="--color-dark url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/VvJmGf.png?v=2022-09-27T14:36:13.884Z) 50% 15%/contain no-repeat"
		>
			<Override slot="SectionContent" sm-align-items="center" padding="250px 0px 0px 0px" />
			<Box padding="0px 20px 0px 20px" margin="0px 0px 16px 0px">
				<Text
					margin="16px 0px 0px 0px"
					font="normal small-caps 500 14px/1.2 --fontFamily-sans"
					display="block"
					text-align="center"
					color="--grey"
					letter-spacing="2.8px"
				>
					GAME DEVELOPED BY
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					font="--base"
					display="block"
					text-align="center"
					color="--light"
				>
					Super8bitrafa@thestreetlevelhero.com
				</Text>
			</Box>
			<Link
				margin="3px 3px 3px 3px"
				color="#F7FBFF"
				text-decoration-line="initial"
				display="inline-block"
				hover-background="--color-darkL1"
				href="https://twitter.com/Super8BitRafa"
				target="_blank"
				background="--color-darkL2"
				padding="12px 20px 12px 20px"
				border-radius="2px"
				text-align="center"
				max-width="400px"
				align-self="center"
				width="100%"
			>
				🐦 Twitter
			</Link>
			<Link
				margin="3px 3px 3px 3px"
				color="#F7FBFF"
				text-decoration-line="initial"
				display="inline-block"
				hover-background="--color-darkL1"
				href="https://www.youtube.com/channel/UCAugKcQ1IiR39wm81KjTvQg"
				target="_blank"
				background="--color-darkL2"
				padding="12px 20px 12px 20px"
				border-radius="2px"
				text-align="center"
				max-width="400px"
				align-self="center"
				width="100%"
			>
				🎥Youtube
			</Link>
			<Link
				margin="3px 3px 3px 3px"
				color="#F7FBFF"
				text-decoration-line="initial"
				display="inline-block"
				hover-background="--color-darkL1"
				href="https://super8bitrafa.itch.io/"
				target="_blank"
				background="--color-darkL2"
				padding="12px 20px 12px 20px"
				border-radius="2px"
				text-align="center"
				max-width="400px"
				align-self="center"
				width="100%"
			>
				🎮Itch.io
			</Link>
			<Link
				margin="3px 3px 3px 3px"
				color="#F7FBFF"
				text-decoration-line="initial"
				display="inline-block"
				hover-background="--color-darkL1"
				href="https://jacobrafaelestrada.wixsite.com/streetlevelhero"
				target="_blank"
				background="--color-darkL2"
				padding="12px 20px 12px 20px"
				border-radius="2px"
				text-align="center"
				max-width="400px"
				align-self="center"
				width="100%"
			>
				🕸️Website
			</Link>
			<Link
				margin="3px 3px 3px 3px"
				color="#F7FBFF"
				text-decoration-line="initial"
				display="inline-block"
				hover-background="--color-darkL1"
				href="https://linktr.ee/super8bitrafa"
				target="_blank"
				background="--color-darkL2"
				padding="12px 20px 12px 20px"
				border-radius="2px"
				text-align="center"
				max-width="400px"
				align-self="center"
				width="100%"
			>
				🔗Other links
			</Link>
		</Section>
		<Components.Htmlform />
		<Section background-color="--dark" text-align="center" padding="32px 0" sm-background="#0f1923">
			<Menu
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
				display="flex"
				justify-content="center"
				font="--lead"
			>
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
			</Menu>
			<Link
				href="mailto:hello@company.com"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
			/>
		</Section>
		<Link href="#" color="#000000" />
	</Theme>;
});